import React from 'react';
import RegresarInicio from '../comunes/RegresarInicio';
import NuestrosServicios from '../comunes/NuestrosServicios';
import Menu from '../comunes/Menu';
function Servicios() {
    return(
            <React.StrictMode>
                 <Menu/> 
                 <RegresarInicio/>
                 <NuestrosServicios/>
            </React.StrictMode>
);
}
export default Servicios;