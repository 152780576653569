import classNames from 'classnames';
import React, { useState, useEffect }  from 'react';
import '../../styles/regresarInicio.css';
import logoRegresarInicio from '../../assets/regresar_inicio.svg';
import logoRegresarAzul from '../../assets/regresar_azul.svg';
const RegresarInicio = () =>  {
    const actualUrl = window.location.pathname.split('/').pop();
    const [isContacto, setIsContacto] = useState(true);
      useEffect(() => {
        if (actualUrl === "contacto")  {
            setIsContacto(true); // Esta en la página de contacto
        } else {
            setIsContacto(false); // No está en la página de contacto
        }
      }, []);
      return (
    <div className = {classNames('div-regresar-inicio',actualUrl)}>
        <a href="/">
            <img className = {classNames('regresar-inicio',actualUrl)} src={isContacto ? logoRegresarAzul : logoRegresarInicio} alt="Inicio" />
        </a>    
     </div>
    )
}
export default RegresarInicio;