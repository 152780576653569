import classNames from 'classnames';
import '../../styles/contactenos.css';
import React from 'react';
import contactos from '../../assets/contactos.png';
import circuitoAzul from '../../assets/circuito_azul_derecha.svg';
const Contactenos = () =>  {
    const actualUrl = window.location.pathname.split('/').pop();
      return (
     <div className = {classNames('div-contactenos',actualUrl)}>
        <div className = {classNames('div-contactos',actualUrl)}>
            <img className = {classNames('img-contactos',actualUrl)} src={contactos} alt="Contatos" />
        </div>
        <div className = {classNames('div-circuito-azul',actualUrl)}>
            <img className = {classNames('img-circuito-azul',actualUrl)} src={circuitoAzul} alt="Contactos" />
        </div>
     </div>
    )
}
export default Contactenos;


