import classNames from 'classnames';
import '../../styles/nuestrosservicios.css';
import '../../styles/base.css';
import React, { useEffect } from 'react';
import circuitos_izquierda from '../../assets/circuito_izquierda.svg';
import imagen_cloud from '../../assets/cloud.png';
import imagen_so from '../../assets/SO.png';
import imagen_bd from '../../assets/bd.png';
import imagen_crm from '../../assets/crm.png';
import imagen_dw from '../../assets/dw.png';
import imagen_sa from '../../assets/wl.png';
import imagen_of from '../../assets/of.png';
import imagen_m from '../../assets/mi.png';
import imagen_back from '../../assets/ba.png';
import imagen_front from '../../assets/fe.png';
import imagen_ia from '../../assets/ia.png';
import imagen_mp from '../../assets/manejo_proyectos.png';
import imagen_ae from '../../assets/adm_tecno.png';
import imagen_rm from '../../assets/registro_marca.png';


const NuestrosServicios = () =>  {
    const actualUrl = window.location.pathname.split('/').pop();
      return (
        <div className = {classNames('div-nuestros-servicios',actualUrl)}>
            <div className = {classNames('nuestros-servicios',actualUrl)} >Servicios</div>
            <div className = {classNames('nuestros-servicios-cubrimos',actualUrl)} >Cubrimos todas las capas</div>
            <div className = {classNames('nuestros-servicios-administracion',actualUrl)} >de administración y desarrollo</div>
                    <div className = {classNames('div-servicios',actualUrl)}>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_cloud} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Implementación <br/>de Nubes</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >AWS / Google / Oracle</div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_so} alt="SO" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Administración <br/> de Sistemas<br/>Operativos</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >Windows / Linux / Solaris</div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_bd} alt="BD" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Administración<br/>de Base de Datos</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >Relacionales / NoSql</div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_dw} alt="BD" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Datawarehouse</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >AWS Redshit / Oracle AUT</div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_sa} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Servidores de <br/>Aplicaciones</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >Weblogic</div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_crm} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Siebel<br/>CRM</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >Administración / Desarrollo </div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_of} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Oracle Fusion</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >ERP / EPM</div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_m} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Integraciones <br/>y Microservicios</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >AWS / Google / Oracle</div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_back} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Desarrollo <br/>Backend</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >Java / Python /AWS Lambda</div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_front} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Desarrollo<br/>Frontend</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >React / Angular / Fluter</div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_ia} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Inteligencia<br/>Artificial</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >AWS Sagemaker </div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_mp} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Manejo de<br/>Proyectos</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >Estrategia / Supervisión / Cumplimiento </div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_ae} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Administración de <br/>Tecnología<br/>Empresarial</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >Gestión tecnológica estratégica </div>
                            </div>
                        </div>
                        <div className = {classNames('div-cuadro-servicios',actualUrl)}>  
                            <img className = {classNames('imagen-servicio',actualUrl)} src={imagen_rm} alt="Cloud" />
                            <div className = {classNames('div-letra-cuadro-servicios',actualUrl)}> 
                                <div className = {classNames('titulo-servicio',actualUrl)} >Creación y Registro<br/>de Marca</div>
                                <div className = {classNames('descripcion-servicio',actualUrl)} >Registro Legal / Identidad Corporativa </div>
                            </div>
                        </div>
                    </div> 
            <div className = {classNames('div-circuitos-izquierda',actualUrl)}>
                <img className = {classNames('circuitos-izquierda',actualUrl)} src={circuitos_izquierda} alt="Circuitos" />
            </div>
        </div>
    )
}
export default NuestrosServicios;


