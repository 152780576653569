import React from 'react';
import RegresarInicio from '../comunes/RegresarInicio';
import Contactenos from '../comunes/Contactenos';
import Menu from '../comunes/Menu';
function Contacto() {
    return(
            <React.StrictMode>
                 <RegresarInicio/>
                 <Contactenos/>
            </React.StrictMode>
);
}
export default Contacto;