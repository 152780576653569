import classNames from 'classnames';
import fondo from '../../assets/wallgradient copia.png';
import iconFlagMexico from '../../assets/icon-flagmexico.png';
import flechaAbajo from '../../assets/flechaabajo.svg';
import React, { useEffect }  from 'react';
function Base() {
     const actualUrl = window.location.pathname.split('/').pop();
      
    return(  
          <div className = {classNames('base',actualUrl)}>
               <div className = {classNames('div-fondo',actualUrl)}>
               <img className = {classNames('fondo',actualUrl)} src={fondo} alt="MUNUNO" /> 
                    <div className = {classNames('linea-gris',actualUrl)}></div>
                    <div className = {classNames('div-flag-mexico',actualUrl)}>
                          <img className = {classNames('flag-mexico',actualUrl)} src={iconFlagMexico} alt="MUNUNO" />
                          <img className = {classNames('flecha-abajo',actualUrl)} src={flechaAbajo} alt="MUNUNO" />
                          <div className={classNames('div-lenguaje',actualUrl)}>Español</div>
                    </div>
                    <div>
                         <a className = {classNames('infomununo',actualUrl)} href="mailto:info@mununo.com">
                              info@mununo.com
                         </a>
                    </div>
               </div>     
          </div>
   )
   }
   export default Base;