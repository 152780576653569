import React from 'react';
import './styles/index.css';
import './styles/base.css';
import './styles/fonts.css';
import Base from './components/comunes/Base.jsx';
import MununoAzul from './components/comunes/MununoAzul.jsx';
import SearchBar from './components/comunes/SearchBar.jsx';
import Inicio from './components/paginas/Inicio.jsx';
import Conocenos from './components/paginas/Conocenos.jsx';
import Servicios from './components/paginas/Servicios.jsx';
import Contacto from './components/paginas/Contacto.jsx';
import LeerMas from './components/comunes/LeerMas.jsx';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const App = () => {
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.mununo.com" />
            </Helmet>
            <BrowserRouter>
                <Base/>
                <MununoAzul/>
                <SearchBar/>
                <Routes>
                    <Route path="/" element={<Inicio/>}/>
                    <Route path="/conocenos" element={<Conocenos/>}/>
                    <Route path="/servicios" element={<Servicios/>}/>
                    <Route path="/contacto" element={<Contacto/>}/>
                </Routes>
            </BrowserRouter>
        </>   
    );
}
export default App;